@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.termsList {
  padding-left: spacing(3);
  margin: 0;
  line-height: 1.5;

  li {
    font-weight: bold;
  }

  li > * {
    font-weight: normal;
  }
}

.underCutList {
  .listTitle {
    font-weight: 600;
  }
}

.rootTitle {
  min-width: 200px;
  margin-right: spacing(4);
  font-weight: 600;
}

.descTitle {
  margin-bottom: 8px;
  font-weight: 600 !important;
}

.underCutArrow {
  transition: transform 0.2s ease-in-out;

  &.expanded {
    transform: rotate(180deg);
  }
}
