@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.formContainer {
  flex-grow: 1;
  max-width: 540px;
  width: 100%;
  padding: spacing(4) spacing(4);

  @media screen and (max-width: 575px) {
    max-width: unset;
    padding: spacing(3);
    width: 95%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    max-width: unset;
    padding: spacing(3);
  }

}

.imgContainer {
  position: relative;
  img {
    width: 100%;
  }
}

.logo {
  display: block;
  margin: 0 auto;
  width: 170px;
}

.banner {
  $banner-margin: spacing(2.5);

  position: absolute;
  width: calc(100% - #{2 * $banner-margin});
  margin: 0 $banner-margin;
  padding: spacing(2) spacing(8);
  background-color: $common-white;
  bottom: $banner-margin;

  .bannerTitle {
    position: relative;
    background-color: $common-white;
    @include font-title();
    line-height: 1.3;
    text-transform: uppercase;
    padding: 0 spacing(2);
    z-index: 1;
  }

  .bannerSubtitle {
    font-size: $font-size-xs;
    text-transform: capitalize;
    line-height: 1.2;
    text-align: center;
    color: #828282;
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }

  .crossline {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: $brawn-color;
  }
}
.quizBtn {
  border: none;
  background-color: $primary-color;
  width: 190px;
  min-width: 140px;
  height: 100%;
  color: $common-white;
  cursor: pointer;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .subTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-style: italic;
  }

  .line {
    content: '';
    width: 20px;
    height: 1px;
    background-color: $common-white;
  }
  svg {
    margin-left: 10px;
  }
}
.closeBtn {
  right: 20px;
  top: 20px;
}

.homepageBtn {
  width: 190px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
