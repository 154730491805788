@import 'styles/variables';
@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  width: 450px;
}

.notificationThumbnail {
  @include circle(36);
  background-color: $error-color;
  color: $common-white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogLink {
  display: block;
  text-decoration-line: underline;
  color: $primary-color;
}
.dialogsRoot{
  padding-left: 0 !important;
}

.notificationsRoot {
  min-width: 500px;
  overflow-y: hidden;
  padding: spacing(3) spacing(2);
}

.noMessagesPlug {
  width: 100%;
}

.markAllRead {
  color: $primary-color;
  font-size: $font-size-small;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  transition: color .3s ease;
  &:hover {
    color: #08767f;
    cursor: pointer;
  }
}
