@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.contentWrapper {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding-top: spacing(6);
}

.title {
  @include font-title();
  font-size: 24px;
  line-height: 1.3;
  text-transform: uppercase;
}

.subTitle {
  font-size: $font-size-xs;
  font-style: italic;
  color: $gray-6;
}

.banner {
  position: relative;

  img {
    position: relative;
    z-index: 1;
    display: block;
    margin-left: auto;
  }
}

.bannerShape {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  background-color: $gray-2;
  opacity: 0.5;
  clip-path: polygon(0 0, 100% 40%, 100% 100%, 0% 100%);
}

.bannerQuote {
  @include font-title();
  font-size: 36px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-bottom: spacing(4);
}

.bannerAuthor {
  font-style: italic;
  font-size: $font-size-big;
  text-transform: uppercase;
  line-height: 1.6;
}

.bannerTextWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  @include px(6);
  padding-top: 18%;
}
