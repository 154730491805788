@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.verifyButton {
  border: solid 4px #87bdc3;
  border-radius: 6px;
  padding: 5px 20px;
  text-transform: uppercase;
  font-family: $font-family-title;
  text-align: center;
  width: fit-content;
  margin: 5px auto;
}

.subInputText {
  color: #87bdc3;
  font-size: 14px;
  padding: 0 24px;
  margin: 10px 0;
}
