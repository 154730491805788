@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  border-radius: 0;
  padding: 0;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
    min-height: 100vh;
  }
}

.about {
  width: 45%;
  flex: 0 0 45%;
  padding: spacing(6.5) spacing(3) 0 spacing(4.5);

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 16px;
  }
}

.title {
  @include font-title();
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1.3;
  color: $primary-color;
  font-weight: normal;
  margin-bottom: spacing(1.5);

  @media screen and (max-width: 1024px) {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
  }
}

.description {
  font-style: italic;
  line-height: 1.7;
  margin-bottom: spacing(7);

  @media screen and (max-width: 1024px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: spacing(2);
  }
}

.caption {
  @media screen and (max-width: 1024px) {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

.stepTitle {
  //@include font-title();
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.stepDescription {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: #4F4F4F;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    position: relative;
    padding-left: spacing(2.5);
    line-height: 1.8;

    &:not(:last-child) {
      margin-bottom: spacing(1);
    }

    &::after {
      content: '';
      position: absolute;
      top: 9px;
      left: 0;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $brawn-color;
    }
  }
}

.separator{
  padding: 0 16px;

  div {
    border-top: 1px solid #C4C4C4;
  }
}

.banner {
  margin-top: 24px;
  margin-bottom: 48px;
  background: $primary-color;
  padding: 12px 16px;
  color: $common-white;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  @include font-title();
}

.closeBtn {
  svg {
    width: 18px;
    height: 18px;
    color: $common-white;
  }
}

.experienceField {
  @media screen and (max-width: 1024px) {
    textarea {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.formContainer {
  padding: spacing(3) spacing(3) spacing(6) spacing(4.5);

  @media screen and (max-width: 1024px) {
    padding: 16px;
  }
}

.addFieldButton {
  font-size: 12px;
  margin: 10px 0;
}

.fieldError {
  position: absolute;
  color: $error-color;
  font-size: 12px;
  margin-top: -20px;

  &.referredError {
    margin-top: 5px;
  }
}

.radioGroupWrapper {
  label {
    span {
      color: #87bdc3;
    }
  }
}
