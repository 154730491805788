@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.checkbox {
  @media screen and (max-width: 1024px) {
    padding-left: 10px;

    label {
      display: flex;
      align-items: flex-start;
      margin-right: 0;

      & > span {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
}
