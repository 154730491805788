@import 'styles/variables';
@import 'styles/functions';

.root {
  height: 110px;
  padding: 0 spacing(1);
  &:hover {
    background-color: $gray-2;
  }

  &:not(:last-child) {
    margin-bottom: spacing(1);
  }
}

h4 {
  font-weight: bolder;
  font-size: $font-size-xs;
  margin-bottom: spacing(1);
}

.textContent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  min-width: 250px;
  width: 300px;
  font-size: $font-size-xs;
  margin-bottom: spacing(2);
}

.imageContainer {
  display: flex;
  object-fit: contain;
  margin-right: spacing(2);

  img {
    width: 60px;
    height: auto;
    max-height: 90px;
  }
}

.itemName {
  font-style: italic;
  margin-bottom: spacing(1);
}

.link {
  transition: color .3s ease;
  &:hover {
    color: #08767f;
    cursor: pointer;
  }
}
