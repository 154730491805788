@import 'styles/variables';
@import 'styles/functions';

.root {
  border-right: 2px solid $error-color;
  cursor: pointer;
  padding: spacing(1) spacing(2);
  &:hover {
    background-color: $gray-2;
  }

  &:not(:last-child) {
    margin-bottom: spacing(1);
  }
}
.textContent{
  min-width: 250px;
}
