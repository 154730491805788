@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.item {
    max-width: 35%;
    flex: 1;
    padding: 0 spacing(2.5);
    display: flex;
    align-items: center;
    flex-direction: column;

    &:not(:last-child) {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 35px;
            left: 75%;
            width: 50%;
            height: 1px;
            border-bottom: 1px dashed $gray-3;
        }
    }
}

.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 130px;
}

.title {
    @include font-title();
    letter-spacing: 0.07em;
    line-height: 1.3;
    margin-top: spacing(2);
}

.about {
    flex: 0 0 40%;
}

.description {
    font-size: $font-size-small;
    line-height: 1.4;
    margin-bottom: spacing(1);
    text-align: center;
    max-width: 250px;
}

.descriptionSm {
    font-size: $font-size-xs;
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: $font-size-small;

    li {
        line-height: 1.9;
        margin-bottom: spacing(0.5);
        position: relative;
        padding-left: spacing(1);

        &::after {
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            display: inline-block;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: $text-color;
        }
    }
}

.listWithoutDots{
  li{
    padding-left: 0;

  }
  li:after{
    display: none;
  }
}

.listSm {
    font-size: $font-size-xs;

    li {
        &::after {
            top: 9px;
        }
    }
}

.icon {
    @include circle(70);
    background-color: $brawn-color;
    color: $common-white;
}
