@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
    padding: spacing(6.5) spacing(3);
    max-width: 100%;
    width: 1680px;
    margin: 0 auto;
}

.title {
    @include font-title();
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
}

.mainBannerTitle {
    @include font-title();
    font-size: 32px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    color: #87BDC3;
}

.description {
    font-weight: 300;
    letter-spacing: 0.03em;
    font-style: italic;
    line-height: 1.9;
    color: #87BDC3;
}

.bannerDescription {
    font-weight: 300;
    letter-spacing: 0.03em;
    font-style: italic;
    line-height: 1.9;
}

.container {
    background-color: $common-white;
    padding: spacing(2) spacing(2) spacing(10);
}

.btnCode {
    font-weight: 300;
    line-height: 1.3;
}

.btnCodeTitle {
  padding: 0 spacing(1);
    font-weight: 600;
}

.listContainer {
    width: 100%;
    background: $app-bg;
    padding: 25px;

  .listTitle {
    @include font-title();
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    color: #87BDC3;
  }
}

.list {
    padding: 0;
    margin: spacing(2) 0 0;
    list-style-type: none;

    &:first-of-type {
        margin: spacing(2) 0 spacing(6);
    }

    li {
        position: relative;
        padding-left: spacing(2.5);
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2;

        &:not(:last-child) {
            margin-bottom: spacing(1.5);
        }

        &::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #87BDC3;
        }
    }
}

.banner {
    position: relative;
    margin-bottom: 220px;

    img {
        width: auto;
        position: relative;
        z-index: 1;
        display: block;
        margin-left: auto;
    }
}

.bannerShape {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 90%;
    height: 100%;
    background-color: $gray-2;
    opacity: 0.5;
    clip-path: polygon(0 0, 100% 45%, 100% 100%, 0% 100%);
}


.bannerQuote {
    @include font-title();
    text-transform: uppercase;
    line-height: 1.7;
    font-size: $font-size-small;
    margin-bottom: spacing(2);
    letter-spacing: 0.07em;;
}

.video {
    position: relative;
    overflow: hidden;
    max-width: 600px;
    margin-left: 50px;

    img {
        width: 100%;
        transition: transform 0.25s ease;
    }
}

.videoText {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.videoTitle {
    color: $common-white;
    font-size: 36px;
    line-height: 1.3;
    margin-bottom: spacing(5);
}

.videoLink {
    color: $common-white;

    &:hover {
        color: $primary-color;
    }

    svg {
        width: 73px;
        height: 73px;
    }
}
.bannerBg {
  position: absolute;
  top: spacing(-6);
  left: 0;
  width: 100%;
  height: calc(100% + #{spacing(6)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
}

.bannerContent {
  top: 0;
  z-index: 1;
  position: relative;

  .bannerTitle {
    @include font-title();
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
  }

  img {
    margin-bottom: 5px;
  }
}

.bannerButton {
  position: absolute;
  right: 20px;
  top: 20px;
}

.leftColumn {
  z-index: 0;
}

.mainContent {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 50px;
  }
}