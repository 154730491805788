@import 'styles/variables';

.root {
    min-height: 600px;
}

.title {
    line-height: 1.6;
}

.btn {
    min-width: 100px;
}

.item {
    border-top: 1px solid $gray-2;
}

.itemDescription {
    color: $gray-6;
}

.titleSuccess {
    font-size: 30px;
    line-height: 1.6;
}

.descriptionSuccess {
    line-height: 1.8;
}