@import 'variables';

@font-face {
  font-family: 'Walkway Ultrabold';
  src: url('../assets/fonts/walkwayUltraBold.ttf');
}

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  color: $text-color;
}

body {
  margin: 0;
  background-color: $app-bg;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

a {
  text-decoration: none;
  outline: none;
  color: $text-color;
  -webkit-tap-highlight-color: transparent;
}

button,
input,
select,
textarea {
  outline: none;
}

// For prevent whitespace under these elements
img,
svg {
  vertical-align: top;
}

img {
  width: 100%;
  height: 100%;
}

.full-loader{
  height: 100vh;
}

.mobileMedia{
  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.desktopMedia{
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
