@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.menuPaper {
  min-width: 150px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: spacing(1);
  width: 100px;

}

.menuList {
  font-size: $font-size-small;
  font-family: $font-family-main;
}

.menuItem {
  padding: spacing(1) spacing(2) spacing(1) spacing(2);
  transition: 0.2s linear background;
  cursor: pointer;
  white-space: normal;

  &:hover {
    background: $primary-color;
  }

  a, span {
    width: 100%;
    padding: 4px 0;
    font-family: $font-family-main;
    font-size: $font-size-small;
  }
}
