@import 'styles/variables';
@import 'styles/functions';

.input {
  resize: none;
  width: 100%;
  height: 100%;
  padding: spacing(1.5) 0;
  border: none;
  font-size: $font-size-small;
  font-family: $font-family-main;
  font-style: italic;
  font-weight: 300;

  &::placeholder {
    font-weight: normal;
    color: $gray-1;
  }
}
