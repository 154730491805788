@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  background-color: $common-white;
  padding: spacing(3) spacing(10);

  @media screen and (max-width: 1024px) {
    padding: spacing(3);
  }
}

.column {
  font-size: $font-size-small;
  font-style: italic;
  align-self: flex-start;
  padding-right: spacing(3);
}

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.horizontalDivider {
  width: 1px;
  height: 75%;
  background-color: $gray-3;
  margin: 0 spacing(9);

  @media screen and (max-width: 1441px) {
    margin: 0 spacing(8);
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.socialWrapper {
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1441px) {
    flex-direction: column;
    align-items: start;
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
}

.socialLinksTitle {
  @include font-title();
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.2;
  margin-right: spacing(5);

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
}

.socialLinkBtn:hover {
  background-color: darken($primary-color, $amount: 8);
}
