@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  margin: -24px;
  padding: 130px 184px;

  @media screen and (max-width: 768px) {
    padding: 24px;
  }

}

.content {
  background-color: rgba($common-white, 0.92);
  padding: 56px;
  border-radius: 24px;
  position: relative;
  max-width: 540px;
  margin: 0 auto;

  & svg {
    display: block;
  }

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
}

.icon {
  max-width: 136px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);

  @media screen and (max-width: 768px) {
    display: none;
  }

}

.title {
  font-family: $font-family-main;
  font-weight: 700;
  color: $text-color;
  font-size: 20px;
  margin-bottom: 24px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  & + svg {
    height: 88px;
    width: auto;
    max-width: 100%;

    & + svg {
      height: 88px;
      width: auto;
      margin-top: 16px;
      max-width: 100%;
    }
  }
}

.description {
  font-family: $font-family-main;
  font-weight: 700;
  color: $text-color;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 40px;

  & span {
    display: block;
    color: $primary-color;
    margin-top: 5px;
  }
}

.inputWrapper {
  width: 100%;

  & label {
    font-family: $font-family-main;
    font-size: 12px;
    font-weight: 400;
    color: $gray-4;
    display: block;
  }

  & input {
    background-color: transparent;
    display: block;
    width: 100%;
    font-family: $font-family-main;
    font-size: 14px;
    font-weight: 600 !important;
    padding: 4px 0;
    color: $text-color;
    border: none;
    outline: none;
    border-bottom: 1px solid $gray-1;
    margin-bottom: 40px;
  }
}
