@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.xmasonry {

  & .xblock {
    padding: 8px;
    cursor: pointer;

    & div:first-child:hover {
      img + div {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
