.root {
  position: relative;
  cursor: pointer;

  .text {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #333333;
    color: #fff;
    border-radius: 20px;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease;
    padding: 4px 16px;
    min-width: 150px;
    width:fit-content;
    text-align: center;
    font-size: 12px;
    line-height: 1;
  }

  &:hover {
    .text {
      bottom: -150%;
      visibility: visible;
      opacity: 1;

      &.topPlacement {
        bottom: 150%;
      }

      &.bottomPlacement {
        bottom: -150%;
      }
    }

  }
}
