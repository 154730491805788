@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
}
.subInnerTitle {
  color: $primary-color;
  text-transform: uppercase;
  font-weight: 600;
}

.subTitle {
  font-size: $font-size-small;
  font-style: italic;
  text-align: center;
  color: #828282;
}
.freeTitle {
  width: 100%;
  background: $brawn-color;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  color: $common-white;
  padding: 5px;
  text-transform: uppercase;
  margin-bottom: spacing(4);
}

.link {
  border: none;
  background: none;
  padding: spacing(0.5) spacing(0.5) spacing(0.5) 0;
  color: $gray-4;
  font-size: $font-size-xs;
  line-height: 1.3;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.passwordLabel {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 5px;
  color: $gray-1;
}
.withoutMarginB {
  margin-bottom: 0 !important;
}
.iMarginBottom {
  margin-bottom: 8px !important;
}

.oneLineInput {
  flex-grow: 1;
}

.oneLineInput + .oneLineInput {
  margin-left: spacing(2);
}

.agree {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.stylesModal {
  height: fit-content;
  max-height: unset;
}
.stylesModalContainer {
  overflow: auto;
  align-items: unset;
}
.stylesTitle {
  font-family: $font-family-title;
  font-size: 22px;
  font-weight: 400;
  line-height: 33.44px;
  letter-spacing: 0.07em;
  text-align: center;
  text-transform: uppercase;
}
.stylesSubTitle {
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 24.57px;
  letter-spacing: 0.03em;
  text-align: center;
  color: $gray-4;
  text-transform: capitalize;
  margin-bottom: 40px;
}
.logo {
  display: block;
  margin: 0 auto;
  width: 170px;
}
