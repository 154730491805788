@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.formContainer {
  flex-grow: 1;
  max-width: 480px;
  padding: spacing(5) spacing(4);

  @media screen and (max-width: 1024px) {
    max-width: unset;
    padding: spacing(3);
  }
}

.imgContainer {
  position: relative;

  img {
    width: 100%;
  }
}

.title {
  @include font-title();
  font-size: 22px;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
}

.subTitle {
  font-size: $font-size-big;
  font-style: italic;
  text-align: center;
  margin-bottom: 40px;
}
.btnContainer {
  width: 80%;
  margin: auto;
  padding: 40px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.listPreferences {
  color: #d9cab6;
}
.preferencesTitle {
  font-size: $font-size-big;
  @include font-title();
  margin: 20px 0 10px;
  color: $text-color;
}
.banner {
  $banner-margin: spacing(2.5);

  position: absolute;
  width: calc(100% - #{2 * $banner-margin});
  margin: 0 $banner-margin;
  padding: spacing(2) spacing(8);
  background-color: $common-white;
  bottom: $banner-margin;

  .bannerTitle {
    position: relative;
    background-color: $common-white;
    @include font-title();
    line-height: 1.3;
    text-transform: uppercase;
    padding: 0 spacing(2);
    z-index: 1;
  }

  .bannerSubtitle {
    font-size: $font-size-xs;
    font-style: italic;
    text-transform: capitalize;
    line-height: 1.2;
    text-align: center;
    color: #828282;
  }

  .crossline {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: $brawn-color;
  }
}

.closeBtn {
  color: $common-white;
}

.preferencesButton {
  width: 250px;
  margin: 100px auto 0;
}

.quizBtnWrapper {
  text-align: center;
  @include font-title();
  font-size: 18px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.quizBtn {
  border: none;
  background-color: $primary-color;
  width: 190px;
  min-width: 140px;
  height: 100%;
  color: $common-white;
  cursor: pointer;
  border-radius: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

  .subTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-style: italic;
    margin-bottom: 0;
  }

  .line {
    content: '';
    width: 20px;
    height: 1px;
    background-color: $common-white;
  }
  svg {
    margin-left: 10px;
  }
}
