@import 'variables';

// Text

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Images

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.cursor-pointer {
  cursor: pointer;
}

//Position

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Color

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $brawn-color;
}

.text-color {
  color: $text-color;
}

.background-white {
  background-color: $common-white;
}

.chrome-picker {
  box-shadow: none !important;
}

.color-gray-6 {
  color: $gray-6;
}

.slick-dots {
  display: flex !important;
  padding: 0 16px;
  list-style: none;
  justify-content: center;
  gap: 15px;
  color: #87bdc3;
  margin: 16px;

  .slick-active {
    svg {
      color: #87bdc3;
      background: #87bdc3;
      border-radius: 50%;
    }
  }
}
