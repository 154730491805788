@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.title {
  padding-top: spacing(4);
  @include px(2);
  @include font-title();
  font-size: 22px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: spacing(3);
}

.mobileSecondText {
  top: 200px;
}

.iconImg {
  width: 40px;
  margin-right: spacing(2);
}

.interiorImg {
  width: 100%;
}

.ambassadorTitle {
  @include font-title();
  font-size: 30px;
  line-height: 1.3;
  text-align: center;
  margin-bottom: spacing(1);
  letter-spacing: 0.01em;
}

.backgroundRectangle {
  position: absolute;
  bottom: 0;
  right: 20px;
  height: 240px;
}

.ambassadorSubtitle {
  color: $gray-6;
  font-size: $font-size-small;
  font-style: italic;
  text-align: center;
}
