@import 'styles/variables';

$point-size-sm: 16px;
$point-size-md: 24px;
$point-size-lg: 32px;
$point-size-xl: 40px;
$point-size-interactive-sm: 28px;
$point-size-interactive-md: 36px;
$point-size-interactive-lg: 44px;
$point-size-interactive-xl: 52px;

.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid $gray-3;
  z-index: 1;

  &.selected::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid $gray-1;
  }

  &.sm::before {
    width: $point-size-interactive-sm;
    height: $point-size-interactive-sm;
  }

  &.md::before {
    width: $point-size-interactive-md;
    height: $point-size-interactive-md;
  }

  &.lg::before {
    width: $point-size-interactive-lg;
    height: $point-size-interactive-lg;
  }

  &.xl::before {
    width: $point-size-interactive-xl;
    height: $point-size-interactive-xl;
  }
}

.sm {
  width: $point-size-sm;
  height: $point-size-sm;
}

.md {
  width: $point-size-md;
  height: $point-size-md;
}

.lg {
  width: $point-size-lg;
  height: $point-size-lg;
}

.xl {
  width: $point-size-xl;
  height: $point-size-xl;
}

//.interactive {
//  border-width: 1px;
//  border-style: solid;
//  padding: 5px;
//
//  &.sm {
//    width: $point-size-interactive-sm;
//    height: $point-size-interactive-sm;
//  }
//
//  &.md {
//    width: $point-size-interactive-md;
//    height: $point-size-interactive-md;
//  }
//
//  &.lg {
//    width: $point-size-interactive-lg;
//    height: $point-size-interactive-lg;
//  }
//
//  &.xl {
//    width: $point-size-interactive-xl;
//    height: $point-size-interactive-xl;
//  }
//}
