@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

.favouriteStyleItem {
  background: #fff;
  border: 1px solid #87BDC3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: 10px;

  @include font-title();
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  flex-direction: column;

  &.selected {
    background: #87BDC3;
    color: #fff;
    &:hover {
      background: #87BDC3;
    }
  }

  &:hover {
    background: #f8f8f8;
  }

  .styleImage {
    margin-bottom: 10px;
    border-radius: 8px 8px 0 0;
  }

  @media screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 12px;
  }
}
