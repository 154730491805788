@import 'styles/variables';
@import 'styles/functions';

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-3;

  &.end {
    flex-direction: row;
  }

  &.start {
    flex-direction: row-reverse;
  }
}

.input {
  width: 100%;
}

.label {
  display: inline-block;
  color: $gray-4;
  transform: translate(2px, 30px);
  z-index: 1;
  position: relative;
  transition: all 0.15s ease-in-out;

  &.focused {
    color: $primary-color;
    transform: translate(0, 0);
    font-size: $font-size-small;
  }
}

.hasError {
  .inputWrapper {
    border-color: $error-color;
  }

  .input {
    color: $error-color;

    &::placeholder {
      color: $error-color;
    }
  }
}
