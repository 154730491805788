@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  background-color: $common-white;
  @include px(5);

  @media screen and (max-width: 1024px) {
    @include px(2);
  }
}

.logoSection {
  @media screen and (max-width: 1024px) {
    padding: 12px 0;
  }
}

.logo {
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    height: 25px;
  }
}

.searchInput {
  position: absolute;
  right: -3px;
  width: 250px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.authLink {
  display: block;
  font-size: $font-size-small;
}

.verticalDivider {
  width: 1px;
  height: 20px;
  background-color: $gray-3;
  @include mx(2);
}

.fullName {
  font-size: $font-size-small;
  font-weight: 300;
  line-height: 1.3;
  max-width: 150px;
}

.menuItem {
  flex: 1;
}

.navLink {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: 600;
  font-size: 22px;
  line-height: 1.2;
  padding: spacing(1);
  transition: font-size 0.1s linear;
  text-transform: uppercase;

  span:first-child {
    margin-right: 4px;
  }

  @media screen and (max-width: 1024px) {
    font-size: $font-size-small;
  }
}
.mobileNavLink {
  background-color: $primary-color;
  color: $text-color;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 6px;
  border-radius: 8px;
  min-height: 40px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex-direction: column;

  :last-child {
    color: white;
  }
}
.flexGap{
  gap: spacing(2);
}

.navLinkActive {
  font-size: 26px;

  @media screen and (max-width: 1024px) {
    font-size: 11px;
    font-weight: 500;
  }
}

.navLinkDivider {
  width: 1px;
  height: spacing(6);
  background-color: $gray-3;
  @media screen and(max-width: 1024px) {
    display: none;
  }
}
.activeDivider {
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background-color: $primary-color;
  @media screen and(max-width: 1024px) {
    display: none;
  }
}

.infoBtn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: $font-size-xs;
  color: $brawn-color;
  white-space: nowrap;
  line-height: 1.3;
  transition: color 0.25s ease;
  margin-top: spacing(0.5);

  &:hover {
    color: $text-color;
  }
}

.infoIcon {
  width: 14px;
  height: 14px;
  margin-right: spacing(0.5);
}

.laptopMenu {
  @media screen and (max-width: 1440px) {
    margin-right: 8px;
    button {
      white-space: nowrap;
      font-size: $font-size-xs;
    }
  }
}
.laptopNotWrap {
  @media screen and (max-width: 1440px) {
    margin: 0 8px;
  }
}

.pinBtn {
  border-width: 1px;

  & .pinBtnIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color: $common-white;
  }

  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.pinBtnText {
  font-weight: 500;
  line-height: 1;
}

.profileAfter {
  z-index: 1;
  position: absolute;
  height: 15px;
  width: 100%;
  top: 100%;
}

.mobileMenuButton{
  background-color: white;
  border: 2px solid $gray-1;
  border-radius: 50%;
}
