// Any variables which related to design
$app-bg: #FAFAFA;
$primary-color: rgb(135, 189, 195);
$brawn-color: #d9cab6;
$error-color: #eb5757;
$text-color: #333333;
$gray-1: #c4c4c4;
$gray-2: #f2f2f2;
$gray-3: #e0e0e0;
$gray-4: #bdbdbd;
$gray-5: #fdfdfd;
$gray-6: #828282;
$orange-1: #f8ac3c;
$common-white: #ffffff;

$spacing: 8px;

$font-size-main: 16px;
$font-size-xs: 12px;
$font-size-small: 14px;
$font-size-big: 18px;

$font-family-main: 'Poppins', sans-serif;
$font-family-title: 'Walkway Ultrabold', sans-serif;
