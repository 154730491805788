@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  padding: spacing(6);
}

.title {
  @include font-title();
  font-size: $font-size-big;
  text-transform: uppercase;
  line-height: 1.4;
  text-align: center;
  margin-bottom: spacing(3);
}

.emailFakeInput {
  border-radius: 100px;
  padding: spacing(1.75) spacing(3);
  background-color: $gray-2;


  input {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    color: $primary-color;
    font-size: $font-size-big;
    text-align: center;
  }
}

.infoIcon {
  @include circle(32);
  background-color: $gray-2;
  color: $primary-color;
  margin-right: spacing(1.5);
}

.rulesList {
  list-style: none;
  font-size: $font-size-xs;
  font-style: italic;
  line-height: 2;
  color: $gray-4;
}
