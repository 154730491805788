@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.root {
  position: relative;
  background-color: $common-white;
  box-shadow: -20px 20px 40px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all .15s ease-out;
  width: 100%;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background-color: $primary-color;
    color: $common-white;

    .contentWrapper {
      img {
        filter: brightness(1000%);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.contentWrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and (max-width: 1024px) {
    padding: 12px;
    width: 100%;
  }
}

.cardImageWrapper {
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    max-width: 96px;
    width: 100%;
  }

  .cardImage {
    object-fit: cover;
    width: 101%;
    height: 200px;
  }
}

.cardTitle {
  @include font-title();
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-left: 12px;

  @media screen and (max-width: 1024px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
}

.cardDescription {
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.03em;

  @media screen and (max-width: 1024px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
}
