@import "../../../styles/functions";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.root {
  max-width: 1500px;
  border-radius: 0;
  padding: 0;
}

.title {
  @include font-title();
  font-size: 36px;
  line-height: 1.3;
  position: relative;

  &.titleSm {
    font-size: 30px;
  }

  &::before, &::after {
    content: '';
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 200px;
    height: 1px;
    background-color: $gray-3;
  }

  &::before {
    right: 100%;

  }

  &::after {
    left: 100%;
  }
}
.description{
  font-style: italic;
  font-weight: 600;
  margin-bottom: spacing(3);
  max-width: 600px;
  text-align: center;
  line-height: 1.6;
}
.howItWorks {
  padding: spacing(9) spacing(7) spacing(3);
}

.howItWorksItem {
  padding-bottom: spacing(6);
}

.howItWorksTitle {
  font-size: 24px;
  line-height: 1.3;
}

.howItWorksDescription {
  line-height: 1.8;
}

.howItWorksImage {
  width: 150px;
  height: 150px;
  max-width: 150px;
}

.grayBlock {
  background-color: $app-bg;
  padding: spacing(9) spacing(7);
}

.sectionBlock{
  margin-bottom: spacing(12);
}

.selectImagesItem {
  max-width: 25%;
}

.receiveItem {
  .recieveImagesDesc{
    max-width: 300px;
    line-height: 1.6;
    margin: 0 auto;
  }
  &:last-child{
    .recieveImagesDesc{
      max-width:360px;
    }
  }
  &:not(:last-child) {

    .receiveImage {
      max-width: calc(100% - #{spacing(4)} - 1px);
    }

    .receiveImageContainer {
      &:after {
        content: '';
        margin: 0 spacing(2);
        display: inline-block;
        width: 1px;
        max-width: 1px;
        flex: 0 1 1px;
        background-color: $primary-color;
      }
    }
  }
}

.receiveImage {
  max-width: 100%;
}
