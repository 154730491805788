@import 'styles/variables';
@import 'styles/functions';

.root {
  padding: spacing(3);
  border-radius: 12px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.closeBtn {
  color: $gray-1;
  position: absolute;
  right: spacing(1);
  top: spacing(1);
  z-index: 10;
}
