@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.stylesContainer {
  margin-bottom: -(spacing(3));
}

.stylesTitle {
  @include font-title();
  font-size: $font-size-big;
  color: $text-color;
  text-transform: uppercase;

  margin-bottom: spacing(2.5);
}

.imgPreviewModal {
  padding: 0;

  img {
    max-width: 960px;
    max-height: 90vh;
  }
}

.roomTypeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //margin-bottom: -(spacing(5));
}

.roomTypeItem {
  display: flex;
  justify-content: center;
  margin-bottom: spacing(5);

  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  &:nth-child(-n+4) {
    width: 25% !important;

    // TODO: return styles after returning all filter types
    //border-bottom: 2px solid $gray-3;
    //padding-bottom: spacing(5);

    div {
      display: flex;
      justify-content: center;
      height: 200px;
      width: 250px;

      @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: flex-start;
        width: auto;
        height: auto;
      }
    }

    svg {
      height: 150px;
      width: 150px;

      @media screen and (max-width: 1024px) {
        height: 75px;
        width: 75px;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100% !important;
    }
  }
}

.priceOptionIcon {
  @include circle(72);
  border: 2px solid $brawn-color;
  color: $brawn-color;
  margin-bottom: spacing(2);
  user-select: none;

  &.selected {
    background-color: $brawn-color;
    color: $common-white;
  }
}

.priceOptionTitle {
  @include font-title();
  font-size: $font-size-small;
  text-transform: uppercase;
  user-select: none;
}
