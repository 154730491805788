@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';
.wrapper {
  display: flex;
  align-items: center;
  padding: spacing(4);
  margin-top: spacing(4);
  text-align: left;
  box-shadow: 10px 20px 60px rgba(0, 0, 0, 0.1);
  border-radius: 0px 5px 5px 0px;
  border-left: 3px solid $primary-color;
}
.text {
  text-transform: capitalize;
  font-family: $font-family-title;
  font-size: 20px;
  letter-spacing: 0.07em;
  color: $text-color;
}
.infoIcon {
  @include circle(24);
  color: $common-white;
  background-color: $primary-color;
  margin-right: spacing(3);
}
