@import 'styles/mixins';
@import 'styles/variables';

.root {
    border-radius: 0;
    padding: spacing(5) spacing(2);
}

.title {
    @include font-title();
    font-size: 30px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: spacing(2);
}

.description {
    position: relative;
    font-size: $font-size-big;
    line-height: 1.8;
    margin-bottom: spacing(2);
    color: #87bdc3;
    font-weight: 600;
}
