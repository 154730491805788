@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.menuPaper {
  min-width: 150px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: spacing(1);
}

.menuList {
  font-size: $font-size-small;
  font-family: $font-family-main;
}
.menuItem{
  padding: 0 !important;

  a, span{
    padding: spacing(1) 0 spacing(1) spacing(2);
    width: 100%;
  }
}
