@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.imgContainer {
  min-width: 50%;
  width: 50%;
  min-height: 500px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 1024px) {
    min-width: auto;
    width: 100%;
    min-height: auto;
  }
}

.textContainer {
  flex: 1 1 auto;
  padding: spacing(8) spacing(6) spacing(2);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 20px;
  }
}

.title {
  @include font-title();
  font-size: 28px;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: spacing(6);
  color: #87BDC3;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
}

.description {
  @include font-title();
  font-size: 24px;
  line-height: 1.6;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
}

.banner {
  $banner-margin: spacing(2.5);
  margin-top: auto;
  //position: absolute;
  width: 100%;
  padding: spacing(2);
  background-color: $common-white;
  bottom: $banner-margin;

  .bannerTitle {
    position: relative;
    background-color: $common-white;
    @include font-title();
    line-height: 1.3;
    text-transform: uppercase;
    padding: 0 spacing(2);
    z-index: 1;
  }

  .bannerSubtitle {
    font-size: $font-size-xs;
    text-transform: capitalize;
    line-height: 1.2;
    text-align: center;
    color: #828282;
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }

  .crossline {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    background-color: $brawn-color;
  }
}
