@import 'styles/variables';
@import 'styles/functions';
@import 'styles/mixins';

.section {
  position: relative;
  z-index: 1;
  background-color: $common-white;
  padding: spacing(8);
  margin-bottom: spacing(5);
}

.editBtn {
  position: absolute;
  right: spacing(2);
  top: spacing(2);
}

.title {
  @include font-title();
  font-size: 24px;
  line-height: 1.3;
  text-transform: uppercase;
}

.avatarContainer {
  margin-left: spacing(8);
}

.halfWidthContainer {
  width: calc(50% - #{spacing(7)});
}

.oneThirdWidthContainer {
  width: 30%;
}

.deactivateBtn {
  background-color: $error-color;
  color: $common-white;
}

.deactivateDescription {
  font-size: $font-size-xs;
  line-height: 1.7;
  font-style: italic;
  max-width: 50%;
  font-weight: bold;
 }

.verificationPopup {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 40px;
  border-radius: 5px 0 0 5px;
  border-left: 3px solid $error-color;
  padding: spacing(4);
  background-color: $common-white;
  z-index: 1;
  box-shadow: 10px 20px 60px rgba(0, 0, 0, 0.1);

  .title {
    text-transform: initial;
  }
}

.popupDescription {
  font-size: $font-size-small;
  font-style: italic;
  line-height: 2;
  color: $gray-6;
}

.timer{
  min-width: 60px;
  color: $gray-1;
  text-align: right;
  margin-right: spacing(2);
}

.disabledInput {
  color: $text-color !important;
}

svg.disabledInput {
  display: none;
}
