@import 'styles/variables';
@import 'styles/functions';

$gray-btn-default: $gray-3;
$gray-btn-hover: $gray-4;

.basicButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 100px;
  background: none;
  color: $text-color;
  font-size: $font-size-small;
  font-family: $font-family-main;
  font-weight: 600;
  cursor: pointer;
  transition: all 150ms ease-in;

  &.inline {
    display: inline-flex;
    width: auto;
  }

  &.primary,
  .secondary,
  .grey {
    &:disabled {
      border-color: $gray-2 !important;
      background: $gray-2 !important;
      color: $gray-1 !important;
      cursor: not-allowed;

      &:hover {
        box-shadow: none !important;
        border-color: $gray-btn-default !important;
        background-color: $gray-btn-default !important;
      }
    }
  }

  &.xs {
    padding: spacing(0.5) spacing(1);
  }

  &.sm {
    padding: spacing(1) spacing(2);
  }

  &.md {
    padding: spacing(1.5) spacing(2);
  }

  &.lg {
    padding: spacing(1.5) spacing(3.5);
  }

  &.noPadding {
    padding: 0;
  }

  &.square {
    border-radius: 6px;
  }
}

.default {
  &.primary {
    color: $primary-color;
  }

  &.secondary {
    color: $brawn-color;
  }

  &.grey {
    color: $gray-1;
  }
}

.contained {
  &.primary {
    border-color: $primary-color;
    background: $primary-color;
    color: $common-white;

    &:hover {
      box-shadow: 10px 10px 15px rgba(135, 189, 195, 0.4);
    }
  }

  &.secondary {
    border-color: $brawn-color;
    background: $brawn-color;
    color: $common-white;

    &:hover {
      box-shadow: 10px 10px 15px rgba(135, 189, 195, 0.4);
    }

    &:disabled {
      border-color: $gray-2 !important;
      background: $gray-2 !important;
      color: $gray-1 !important;
      cursor: not-allowed;

      &:hover {
        box-shadow: none !important;
        border-color: $gray-btn-default !important;
        background-color: $gray-btn-default !important;
      }
    }
  }

  &.grey {
    border-color: $gray-btn-default;
    background: $gray-btn-default;
    color: $text-color;

    &:hover {
      box-shadow: 10px 10px 15px rgba(135, 189, 195, 0.4);
    }
  }
}

.outlined {
  background: transparent;

  &.primary {
    border-color: $primary-color;
    color: $primary-color;

    &:hover {
      background: $primary-color;
      color: $common-white;
      box-shadow: none;
    }
  }

  &.secondary {
    border-color: $brawn-color;
    color: $brawn-color;
    background: white;

    &:hover {
      background: $brawn-color;
      color: $common-white;
      box-shadow: none;
    }
  }

  &.grey {
    border-color: $gray-btn-default;
    color: $gray-btn-default;

    &:hover {
      border-color: $gray-btn-hover;
      color: $gray-btn-hover;
    }
  }
}
